import React from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  withStyles
} from "@material-ui/core";

class EditDialog extends React.Component {
  actions = (onSave, onCancel, onConclude) => {
    let actions = [];
    if (onConclude) {
      actions.push(
        <div style={this.styles.actionLeft} key="concluded">
          <Button onClick={onConclude}>Concluir</Button>
        </div>
      );
    }
    !!onSave && actions.push(
      <Button color="primary" onClick={onSave} key="save">
        Salvar
      </Button>
    );
    actions.push(
      <Button onClick={onCancel} key="cancel">
        Fechar
      </Button>
    );
    return actions;
  };

  render() {
    const {
      children,
      opened,
      onSave,
      onCancel,
      title,
      onConclude,
      onAttached,
      classes
    } = this.props;
    return (
      <Dialog maxWidth="md" fullWidth={true} open={opened}>
        <DialogTitle className={classes.titleStyle} disableTypography={true}>
          <Typography className={classes.titleStyle} variant="h5">
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions className={classes.actionsContainerStyle}>
          {this.actions(onSave, onCancel, onConclude, onAttached)}
        </DialogActions>
      </Dialog>
    );
  }
}

EditDialog.propTypes = {
  children: PropTypes.node,
  opened: PropTypes.bool,
  onCancel: PropTypes.func,
  title: PropTypes.node.isRequired,
  historyModel: PropTypes.string,
  historyId: PropTypes.number,
  onConclude: PropTypes.func
};

const styles = theme => ({
  titleStyle: {
    background: theme.palette.primary.main,
    color: "white"
  },
  bodyStyle: {
    padding: 0,
    overflowY: "auto",
    height: "450px",
    maxHeight: "450px"
  },
  contentStyle: {
    width: "80%",
    maxWidth: "1000px"
  },
  actionLeft: {
    marginRight: "auto"
  },
  inputStyle: {
    display: "none",
    width: "100%"
  }
});

export default withStyles(styles)(observer(EditDialog));
