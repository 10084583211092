import moment from "moment";

const REQUIRED = (field, item) =>
  !item[field] || item[field] === "" || item[field] === 0
    ? "O valor é requerido"
    : null;

const AFTER_TODAY = (field, item) =>
  REQUIRED(field, item) || moment().diff(item[field], "days") > 0
    ? "A data selecionada deve estar no futuro"
    : null;

const LESS_THAN = (otherField, label) => (field, item) =>
  item[field] >= item[otherField]
    ? `O valor deve ser menor que ${label}`
    : null;

const MORE_THAN = (otherField, label) => (field, item) =>
  item[field] <= item[otherField]
    ? `O valor deve ser maior que ${label}`
    : null;

const MORE_THAN_ZERO = (field, item) =>
  item[field] <= 0 ? "O valor deve ser maior que zero" : null;

const AT_LEAST_ONE = (field, item) =>
  !item[field] || !Array.isArray(item[field]) || item[field].length === 0
    ? "É necessário adicionar ao menos um valor."
    : null;

export {
  REQUIRED,
  AFTER_TODAY,
  LESS_THAN,
  MORE_THAN,
  MORE_THAN_ZERO,
  AT_LEAST_ONE,
};
