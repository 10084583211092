import React from "react";
import { observer } from "mobx-react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import UIStore from "../stores/UIStore";

class ConfirmDialog extends React.Component {
  render() {
    return (
      <Dialog open={UIStore.confirmMessage !== null}>
        <DialogTitle id="alert-dialog-title">
          {UIStore.confirmTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {UIStore.confirmMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => UIStore.hideConfirmDialog(true)}
          >
            Sim
          </Button>
          <Button
            color="primary"
            onClick={() => UIStore.hideConfirmDialog(false)}
          >
            Não
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default observer(ConfirmDialog);
