import React from "react";
import { observer } from "mobx-react";
import { FormControlLabel, Checkbox } from "@material-ui/core";

const CheckBox = ({ item, field, label }) => (
  <FormControlLabel
    control={
      <Checkbox
        checked={item[field]}
        onChange={e => (item[field] = e.target.checked)}
        color="primary"
      />
    }
    label={label}
  />
);

export default observer(CheckBox);
