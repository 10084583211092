import { jsonParser } from "./Client";

const get = (cep) => {
  return new Promise((resolve, reject) => {
    let emptyRes = {
      cep: "",
      logradouro: "",
      complemento: "",
      bairro: "",
      localidade: "",
      uf: "",
      ibge: "",
      gia: "",
      ddd: "",
      siafi: "",
    };

    if (cep) {
      cep = cep.replace(new RegExp("[^0-9]", "g"), "");
      if (cep.length === 8) {
        resolve(
          fetch(`https://viacep.com.br/ws/${cep}/json/`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
        );
      }
    }
    reject(emptyRes);
  });
};

const getCepData = (cep) =>
  get(cep)
    .then(jsonParser)
    .catch((emptyRes) => {
      return emptyRes;
    });

export { getCepData };
