import React from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";

class TextFieldCustom extends React.Component {
  static propTypes = {
    InputLabelProps: PropTypes.object,
    disabled: PropTypes.bool,
    entity: PropTypes.string,
    errorText: PropTypes.string,
    errors: PropTypes.object,
    field: PropTypes.string.isRequired,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.string,
    item: PropTypes.object.isRequired,
    label: PropTypes.string,
    onBlur: PropTypes.func,
    rows: PropTypes.number,
    rowsMax: PropTypes.number,
    type: PropTypes.string,
    valueType: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    errorText: "",
    floatingLabelText: "",
    fullWidth: false,
    helperText: "",
    rows: 1,
    type: "text",
    valueType: "text",
  };

  text = (e) => (this.props.item[this.props.field] = e.target.value);

  document = (e) =>
    (this.props.item[this.props.field] = e.target.value.formatDocument());

  render() {
    const {
      InputLabelProps,
      InputProps,
      disabled,
      errors,
      field,
      fullWidth,
      helperText,
      item,
      label,
      onBlur,
      rows,
      rowsMax,
      style,
      type,
      valueType,
      variant,
    } = this.props;
    return (
      <TextField
        InputLabelProps={InputLabelProps}
        InputProps={InputProps}
        disabled={disabled}
        error={errors && errors.hasOwnProperty(field)}
        fullWidth={fullWidth}
        helperText={errors ? errors[field] : helperText != null ? helperText : null}
        label={label}
        multiline={rows > 1}
        onChange={this[valueType]}
        rows={rows}
        rowsMax={rowsMax}
        style={{ marginBottom: "5px", ...style }}
        type={type}
        value={item[field]}
        variant={variant}
        onBlur={(e) => {
          if (onBlur) onBlur(e);
        }}
      />
    );
  }
}

export default observer(TextFieldCustom);
