import React from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import {
  withWidth,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem
} from "@material-ui/core";

import {
  Home,
  Group,
  CreditCard,
  Person
} from "@material-ui/icons";

import AuthenticationStore from "../stores/AuthenticationStore";
import UIStore from "../stores/UIStore";

class AsideBar extends React.Component {
  navigate = url => {
    let { history } = this.props;
    history.push(url);
  };

  render() {
    let { classes, location } = this.props;
    let telas = [{ label: "Início", leftIcon: <Home />, to: "/" }];
    let telasRestristas = [
      { label: "Revendedores", leftIcon: <Group />, to: "/users" },
      { label: "Clientes", leftIcon: <Person />, to: "/customers" },
      { label: "Pagamentos", leftIcon: <CreditCard />, to: "/payments" },
    ];

    if (AuthenticationStore.me.role === "ADMIN" || AuthenticationStore.me.role === "MANAGER") {
      telas = telas.concat(telasRestristas);
    }

    return (
      <div
        className={classNames(classes.asideBar, {
          [classes.closed]: !UIStore.asideOpened && !UIStore.forceAside
        })}
      >
        {telas.map((t, i) =>
          t.to ? (
            <MenuItem
              key={i}
              selected={location.pathname === t.to}
              classes={{
                root: classes.menuItem,
                selected: classes.menuItemSelected
              }}
              onClick={() => this.navigate(t.to)}
              className={classes.customMenuItem}
            >
              <ListItemIcon
                className={classes.customListItemIcon}
              >
                {t.leftIcon}
              </ListItemIcon>
              <ListItemText
                inset
                primary={t.label}
                className={classes.customListItemText}
              />
            </MenuItem>
          ) : (
            <Divider key={i} />
          )
        )}
      </div>
    );
  }
}

const styles = theme => ({
  asideBar: {
    width: "65%",
    margin: "auto",
    marginTop: 125,
    marginBottom: -15,
    display: "flex",
    flex: 1,
    flexDirection: "row",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
      marginTop: 40
    },
  },
  menuItemSelected: {
    color: "#00B7B3",
  },
  primary: {
    color: "#666"
  },
  customMenuItem: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 15,
    paddingLeft: 15,
    margin: "0 30px 0 0",
    borderRadius: 4,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  customListItemIcon: {
    padding: 0,
    margin: "-1px 10px 0 0",
    minWidth: "auto"
  },
  customListItemText: {
    padding: 0,
    margin: 0,  
    fontFamily: "Fira Sans",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
});

export default withRouter(withWidth()(withStyles(styles)(observer(AsideBar))));
