import React from "react";
import { observer } from "mobx-react";
import { Checkbox } from "@material-ui/core";
import moment from "moment";
import List from "../../components/List";
import Edit from "./Edit";
import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";
import SortPanel from "../../components/SortPanel";
import FileDownload from "../../components/FileDownload";
import SelectPanel from "../../components/SelectPanel";
import DateRange from "../../components/DateRange";
import SearchField from "../../components/SearchField";
import AuthenticationStore from "../../stores/AuthenticationStore";


const store = Stores.load("paymentHistory", "user.name");
const usersStore = Stores.create("users", "name");
const customersStore = Stores.create("customers", "name");

class Payments extends React.Component {
  componentDidMount() {
    usersStore.size = 9999;
    usersStore.query();
    customersStore.size = 9999;
    customersStore.query();
    store.sort = { column: "createdDate", ascending: false };
    UIStore.init("Pagamentos", store);
  }

  check = () =>
    store
      .check()
      .then(() => store.query())
      .then(() => UIStore.redo(store))
      .catch(UIStore.saveError);

  render() {
    const data = store.list.map((i) => ({
      icon: (
        <Checkbox
          color="primary"
          checked={i.checked}
          onChange={(event) => {
            store.selected = [i];
            this.check();
          }}
          style={styles.checkBox}
        />
      ),
      checked: i.checked,
      item: i,
      important: null,
      title: <div>{`Total: ${i?.total.formatMoney()}`}</div>,
      content: (
        <>
          {i.customer.name}
          <br />
          {moment(i.createdDate).format("DD/MM/YYYY HH:mm")}
          <br />
          {`Comissão paga? ${i.salesCommissionPaid ? "SIM" : "NÃO"}`}
        </>
      ),
    }));

    return (
      <>
        <List
          store={store}
          data={data}
          status={store.list.map((i) => {
            const payment = !!i.payments ? i.payments[0] : {};
            return{
              id: payment?.id,
              label:
                payment?.billingType === "PIX"
                  ? "PIX"
                  : payment?.billingType === "CREDIT_CARD"
                  ? "Cartão de Crédito"
                  : payment?.billingType === "CASH"
                  ? "Dinheiro"
                  : "",
              class: "approved-status",
            }
          })}
          leftPanel={
            <div className="flex flex-col">
              <SelectPanel
                options={usersStore.list}
                store={store}
                entity="user"
                label="Revendedor(a)"
                getLabel={(u) => (u ? u.name : "Todos")}
              />
              <SelectPanel
                  options={customersStore.list}
                  store={store}
                  entity="customer"
                  label="Cliente"
                  getLabel={(u) => (u ? u.name : "Todos")}
                />
              <DateRange label="Período" store={store} />
            </div>
          }
          rightPanel={
            <>
              <SearchField store={store} />
              <FileDownload download={store.report} filename="Relatorio.pdf">
                Vendas
              </FileDownload>
              <SortPanel
                store={store}
                filters={{
                  "user.name": "Nome do Revendedor",
                  value: "Valor",
                  createdDate: "Data de Realização",
                  salesCommissionPaid: "Comissão"
                }}
              />
            </>
          }
          onEdit={() => store.open()}
        />
        <Edit onSave={() => AuthenticationStore.testAuthenticated()} />
      </>
    );
  }
}

export default observer(Payments);

const styles = {
  checkBox: {
    marginRight: 5,
    padding: 3,
    alignSelf: "flex-start",
  },
};
