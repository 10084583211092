import { observer } from "mobx-react";
import moment from "moment";
import React from "react";
import CheckBox from "../../components/CheckBox";
import EditDialog from "../../components/EditDialog";
import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";


const store = Stores.load("paymentHistory", "createdDate");
const PAYMENT_STATUS = {
  CONFIRMED: "Pagamento confirmado",
  RECEIVED: "Pagamento confirmado",
  PENDING: "Aguardando aprovação",
  REFUNDED: "Pagamento estornado",
  OVERDUE: "Pagamento vencido",
};

class PaymentEdit extends React.Component {

  save = () =>
    store
      .save(this.props.onSave)
      .then(() => UIStore.redo(store))
      .catch(UIStore.saveError);

  render() {
    this.item = store.edit != null ? store.edit : {};
    const payment = !!this.item?.payments ? this.item.payments[0] : {};
    const paymentType = payment?.billingType === "PIX"
    ? "PIX"
    : payment?.billingType === "CREDIT_CARD"
    ? "Cartão de Crédito"
    : payment?.billingType === "CASH"
    ? "Dinheiro"
    : "";

    return (
      <EditDialog
        title="Editar Status do Pagamento"
        opened={store.edit != null}
        onCancel={() => (store.edit = null)}
        onSave={this.save}
      >
        <div className="rowStyles">
          <div className="columnStyles">
            <h4 style={styles.liTexts}>
              {`Revendedor(a):  ${this.item?.user?.name}`}
            </h4>
            <h4 style={styles.liTexts}>
              {`Cliente:  ${this.item?.customer?.name}`}
            </h4>
            <h4 style={styles.liTexts}>
              {`Pagamento:  ${paymentType}`}
            </h4>
            <CheckBox label="Comissão paga para revendedor(a)?" field="salesCommissionPaid" item={this.item} />
          </div>
          <div className="columnStyles">
            { this.item?.installmentCount > 1 &&
              <h4 style={styles.liTexts}>Parcelas</h4>
            }
            {this.item?.payments?.map(p =>
              <div style={{ marginTop: 20 }}>
                { this.item.installmentCount > 1 &&
                  <div>
                    <h4 style={styles.liTexts}>
                      {`Pagamento ${p.installmentNumber ?? 1}`}
                    </h4>
                    <h4 style={styles.liTexts}>
                      {`Valor da parcela: ${p.value?.formatMoney()}`}
                    </h4>
                    <h4 style={styles.liTexts}>
                      {`Data de pagamento ${moment(p.createdDate).format("DD/MM/YYYY")}`}
                    </h4>
                  </div>  
                }
                <h4 style={styles.liTexts}>
                  {PAYMENT_STATUS[p.status] ?? p.status}
                </h4>
                { !!p.invoiceUrl &&
                  <a href={p.invoiceUrl} target="_BLANK" rel="noopener noreferrer">
                    <h4 style={styles.liTexts}>Comprovante de pagamento do ASAAS</h4>
                  </a>
                }
              </div>
            )}
          </div>
        </div>
      </EditDialog>
    );
  }
}

export default observer(PaymentEdit);

const styles = {
  liTexts: {
    margin: 0,
  },
};
