import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import React from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import AuthenticationStore from "../stores/AuthenticationStore";

const styles = {
  loginContainer: {
    width: "400px",
    margin: "100px auto",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
  },
  loginSubmit: {
    marginTop: "10px",
  },
  loginImage: {
    marginTop: "5px",
  },
};

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nome: null,
      email: null,
      password: null,
      passwordConfirm: null,
      cpf: null,
    };
  }

  handleChange = (event) => {
    let value = {};
    value[event.target.id] = event.target.value;
    this.setState(value);
  };

  handleRegister = () => {
    var { nome, email, password, passwordConfirm, cpf } = this.state;
    if (nome && email && password && passwordConfirm && cpf) {
      if (password === passwordConfirm) {
        AuthenticationStore.register(nome, email, password, cpf).then(() =>
          this.props.history.push("/")
        );
      }
    }
  };

  handleKeyPress = (target) => {
    if (target.charCode === 13) {
      this.handleRegister();
    }
  };

  render() {
    return (
      <div>
        <Paper style={styles.loginContainer}>
          <Typography variant="headline">Registrar</Typography>
          <TextField
            id="nome"
            label="Nome"
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            fullWidth={true}
            required
          />

          <TextField
            id="email"
            label="Email"
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            fullWidth={true}
            pattern="[^ @]*@[^ @]*"
            required
          />

          <TextField
            id="cpf"
            label="CPF"
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            fullWidth={true}
            required
          />

          <TextField
            id="password"
            label="Senha"
            type="password"
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            fullWidth={true}
          />

          <TextField
            id="passwordConfirm"
            label="Confirmmar Senha"
            type="password"
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            fullWidth={true}
          />

          <Button
            variant="raised"
            color="primary"
            onClick={this.handleRegister}
            fullWidth={true}
            style={styles.loginSubmit}
          >
            Enviar
          </Button>
          <Button
            variant="raised"
            onClick={() => this.props.history.push("/login")}
            fullWidth={true}
            style={styles.loginSubmit}
          >
            Voltar
          </Button>
        </Paper>
      </div>
    );
  }
}

export default observer(withRouter(Register));
