import React from "react";
import { InputBase, withStyles, Switch } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

class SearchField extends React.Component {
  timer = null;

  render() {
    let { classes, store, enableToogle } = this.props;
    return (
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Procurar..."
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput
          }}
          onChange={e => {
            store.page = 0;
            store.queryField = e.target.value;
            clearTimeout(this.timer);
            this.timer = setTimeout(() => store.query(), 300);
          }}
        />
        {enableToogle && (
          <Switch
            checked={store.searchAll}
            onChange={() => {
              store.page = 0;
              store.searchAll = !store.searchAll;
              clearTimeout(this.timer);
              store.query();
            }}
            color="primary"
            title="Mostrar Todos"
          />
        )}
      </div>
    );
  }
}

const styles = theme => ({
  search: {
    width: "100%",
    position: "relative",
    margin: "2px 10px 0 0",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: -3,
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 150
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
  }
});

export default withStyles(styles)(SearchField);
