import React from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import {
  Fab,
  Button,
  Menu,
  MenuItem,
  Divider,
  AppBar,
  Toolbar,
  Snackbar,
  withWidth,
  ListItemIcon
} from "@material-ui/core";

import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import NavigationMoreVert from "@material-ui/icons/MoreVert";

import logoicon from "../assets/logoicon.png";

import AuthenticationStore from "../stores/AuthenticationStore";
import UIStore from "../stores/UIStore";

import AsideBar from "./AsideBar";
import ConfirmDialog from "../components/ConfirmDialog";

class MainBar extends React.Component {
  state = { anchorElUsuario: null, anchorElCargo: null };

  handleToggle = () => UIStore.asideOpen();

  handleSair = () =>
    AuthenticationStore.logout().then(() => this.props.history.push("/login"));

  handleMenuUsuario = event =>
    this.setState({ anchorElUsuario: event.currentTarget });

  handleCloseUsuario = () => this.setState({ anchorElUsuario: null });

  handleMenuCargo = event =>
    this.setState({ anchorElCargo: event.currentTarget });

  getUserMenu = () => {
    let { anchorElUsuario } = this.state;
    let open = Boolean(anchorElUsuario);
    return (
      <>
        <Button
          size="small"
          variant="outlined"
          color="default"
          className={this.props.classes.roundButtons}
          onClick={this.handleMenuUsuario}
        >
          <NavigationMoreVert />
        </Button>
        <Menu
          anchorEl={anchorElUsuario}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={open}
          onClose={this.handleCloseUsuario}
        >
          <MenuItem>
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            {AuthenticationStore.me.email}
          </MenuItem>
          <Divider />
          <MenuItem onClick={this.handleSair}>Sair</MenuItem>
        </Menu>
      </>
    );
  };

  render() {
    let { classes, width } = this.props;
    return (
      <>
        <AppBar
          className={classes.mainHeader}
        >
          <Toolbar elevation={0}>
            <div className={classes.logoContainer}>
              <img className={classes.logoImage} src={logoicon} alt="logo" />
            </div>
            <div className={classes.titleContainer}>
              Olá, Admin!
            </div>
            {this.getUserMenu()}
          </Toolbar>
        </AppBar>

        <AsideBar asideOpen={UIStore.asideOpened} />

        <Fab
          color="default"
          onClick={this.handleToggle}
          style={{
            position: "fixed",
            zIndex: "99999",
            top: "144px",
            left: "16px",
            visibility:
              UIStore.asideOpened ||
              width === "xs" ||
              width === "sm" ||
              width === "md"
                ? "hidden"
                : "visible"
          }}
        >
          <MenuIcon />
        </Fab>

        <Snackbar
          autoHideDuration={4000}
          open={UIStore.snackOpened}
          message={UIStore.snackMessage}
          onClose={() => (UIStore.snackOpened = false)}
        />
        
        <ConfirmDialog />
      </>
    );
  }
}

const styles = theme => ({
  mainHeader: {
    boxShadow: "none",
    borderBottom: "2px solid #ddf5f4",
    position: "fixed",
    [theme.breakpoints.down("sm")]: {
      position: "inherit"
    },
  },
  menuButton: {
    display: "none"
  },
  logoContainer: {
    marginTop: 8,
  },
  logoImage: {
    height: "60px"
  },
  topContainer: {
    display: "flex",
    width: "100%",
    verticalAlign: "top",
  },
  titleContainer: {
    paddingLeft: 20,
  },
  roundButtons: {
    borderRadius: "48px",
    minWidth: "48px",
    minHeight: "48px",
    position: "absolute",
    right: 20,
    top: 13
  },
});

export default withRouter(withWidth()(withStyles(styles)(observer(MainBar))));
