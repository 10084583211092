import React from "react";
import { observer } from "mobx-react";
import { MenuItem, Menu } from "@material-ui/core";

class SortPanel extends React.Component {
  state = { anchorEl: null };

  handleOpen = (event) => this.setState({ anchorEl: event.currentTarget });

  handleSelect = (k) => {
    let { store } = this.props;
    store.sort.ascending =
      (store.sort.column === k && !store.sort.ascending) ||
      store.sort.column !== k;
    store.sort.column = k;
    store.query();
    this.setState({ anchorEl: null });
  };

  render() {
    let { store, filters } = this.props;
    let { anchorEl } = this.state;

    return (
      <>
        <div
          style={{
            width: "100%",
            margin: "2px 10px 0 0",
            position: "relative",
            lineHeight: "35px",
            cursor: "pointer",
          }}
          onClick={this.handleOpen}
        >
          {store.sort.ascending ? " ▲ " : " ▼ "}
          {filters[store.sort.column]}
        </div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => this.setState({ anchorEl: null })}
        >
          {Object.keys(filters).map((k) => (
            <MenuItem key={`k${k}`} onClick={() => this.handleSelect(k)}>
              {(store.sort.column === k && !store.sort.ascending) ||
              store.sort.column !== k
                ? " ▲ "
                : " ▼ "}{" "}
              {filters[k]}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
}

export default observer(SortPanel);
