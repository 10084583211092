import React from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import {
  MuiThemeProvider,
  CssBaseline,
  Snackbar,
  Button,
  TextField,
  Typography
} from "@material-ui/core";

import theme from "../components/Theme";

import fundo from "../assets/login-web-bg.jpg";
import logo from "../assets/logo.png";
import overlay from "../assets/rectangle-overlay.png";

import AuthenticationStore from "../stores/AuthenticationStore";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      password: null,
      message: "",
      loading: false
    };
  }

  handleChange = event => {
    if (event.target.id === "user") {
      this.setState({ user: event.target.value });
    } else if (event.target.id === "password") {
      this.setState({ password: event.target.value });
    }
  };

  handleLogin = () => {
    var { user, password } = this.state;
    if (user && password) {
      AuthenticationStore.login(user, password)
        .then(() => this.props.history.replace("/"))
        .catch(e => {
          this.setState({ message: "Usuário ou senha inválidos!" });
        });
    }
  };

  handleKeyPress = target => {
    if (target.charCode === 13) {
      this.handleLogin();
    }
  };

  render() {
    return (
      <CssBaseline>
        <MuiThemeProvider theme={theme}>
          <div style={styles.loginPageContent}>
            <div style={styles.loginBg} className="loginBg"></div>
            <div style={styles.loginContainer} className="loginContainer">
              <img
                style={styles.loginLogo}
                src={logo}
                alt="logo"
              />
              <Typography
                variant="h4"
                style={styles.loginHeading}
              >
                Entrar - Administrativo
              </Typography>
              <form style={styles.loginForm}>
                <TextField
                  id="user"
                  label="Usuário"
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeyPress}
                  fullWidth={true}
                  variant="outlined"
                  style={styles.loginInput}
                />
                <TextField
                  id="password"
                  label="Senha"
                  type="password"
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeyPress}
                  fullWidth={true}
                  variant="outlined"
                  style={styles.loginInput}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleLogin}
                  fullWidth={true}
                  style={styles.loginButton}
                >
                  Entrar
                </Button>
              </form>
            </div>
            <Snackbar
              open={this.state.message !== ""}
              message={this.state.message}
              autoHideDuration={4000}
              onClose={() => this.setState({ message: "" })}
            />
          </div>
        </MuiThemeProvider>
      </CssBaseline>
    );
  }
}

export default observer(withRouter(Login));

const styles = {
  loginPageContent: {
    display: "flex",
    height: "100vh" 
  },
  loginBg: {
    flex: "1 1 auto",
    backgroundImage: `url(${overlay}), url(${fundo})`,
    backgroundSize: "10px 10px, cover",
    backgroundRepeat: "repeat-y, no-repeat",
    backgroundPosition: "right, left"
  },
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: 370,
    backgroundColor: "#fff",
    padding: 40
  },
  loginLogo: {
    width: 205,
    marginBottom: 90 
  },
  loginHeading: {
    textAlign: "center",
    color: "#666",
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 30,
    fontFamily: "Fira Sans"
  },
  loginForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    width: "100%"
  },
  loginInput: {
    marginBottom: 15,
    fontFamily: "Fira Sans"
  },
  loginButton: {
    height: 50,
    boxShadow: "none",
    fontWeight: "bold",
    fontFamily: "Fira Sans" 
  }
}


