import React from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { FormHelperText } from "@material-ui/core";

class SelectFieldCustom extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    field: PropTypes.string.isRequired,
    list: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    errorText: PropTypes.string,
    label: PropTypes.string,
    multiple: PropTypes.bool,
    entity: PropTypes.string,
    labelFunc: PropTypes.func,
    onChange: PropTypes.func,
    variant: PropTypes.string,
  };

  static defaultProps = {
    list: [],
    disabled: false,
    errorText: "",
    label: "",
    multiple: false,
    inputProps: null,
  };

  handleChange = (event, index) => {
    let { item, field, onChange, list, multiple } = this.props;
    let values = event.target.value;

    item[field] = multiple
      ? list.filter((i) =>
          i.id ? values.indexOf(i.id) >= 0 : values.indexOf(i) >= 0
        )
      : list.find((i) =>
          i.id ? i.id === values : i.value ? i.value === values : i === values
        );
    if (item[field].value) {
      item[field] = item[field].value;
    }
    if (onChange) {
      onChange(event, index, values);
    }
  };

  getMenuItems = () => {
    const { list, item, field, multiple, labelFunc } = this.props;
    return [
      <MenuItem key={-1} value="" />,
      ...list.map((m, i) => (
        <MenuItem
          key={i}
          checked={
            multiple && item[field]
              ? item[field].indexOf(m.id ? m.id : m.value) > -1
              : false
          }
          value={m.value ? m.value : m.id ? m.id : m}
        >
          {labelFunc ? labelFunc(m) : m.label}
        </MenuItem>
      )),
    ];
  };

  render() {
    const {
      disabled,
      errors,
      field,
      inputProps,
      item,
      label,
      multiple,
      style,
      variant,
    } = this.props;

    let value = null;
    if (multiple) {
      value = item[field] ? item[field].map((i) => (i.id ? i.id : i)) : [];
    } else {
      value = item[field] && item[field].id ? item[field].id : item[field];
    }
    if (value === null || value === undefined) {
      value = "";
    }
    return (
      <FormControl style={style}>
        <InputLabel>{label}</InputLabel>
        <Select
          value={value}
          onChange={this.handleChange}
          disabled={disabled}
          multiple={multiple}
          inputProps={inputProps}
          style={{ minWidth: "200px" }}
          variant={variant}
        >
          {this.getMenuItems()}
        </Select>
        <FormHelperText
          style={{
            display:
              errors && errors.hasOwnProperty(field) ? "inherit" : "none",
          }}
        >
          {errors && errors[field]}
        </FormHelperText>
      </FormControl>
    );
  }
}

export default observer(SelectFieldCustom);
