import React from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

class Root extends React.Component {
  render() {
    let { classes, asideOpen, children } = this.props;

    return (
      <div
        className={classNames(
          classes.contentContainer,
          {
            [classes.opened]: asideOpen
          },
          {
            [classes.closed]: !asideOpen
          }
        )}
      >
        {children}
      </div>
    );
  }
}

const styles = theme => ({
  contentContainer: {
    marginTop: "60px",
    position: "relative",
    transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
    height: "100%"
  },
  opened: {
    [theme.breakpoints.up("md")]: {
      width: "100%"
    }
  },
  closed: {
    width: "100%",
    marginLeft: "0"
  }
});

export default withStyles(styles)(Root);
