import React from "react";
import { PictureAsPdf } from "@material-ui/icons";

function FileDownload({ filename, children, download }) {
  const handleAction = async () => {
    const result = await download();
    const blob = await result.blob();
    const href = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.href = href;
    tempLink.setAttribute('download', filename);
    tempLink.click();
  };

  return (
    <div
      style={styles.SpreadsheetDownloadStyle}
      onClick={handleAction}
    >
      <PictureAsPdf styles={{ marginRight: "2px" }} />
      {children}
    </div>
  )
};

export default FileDownload;

const styles = {
  SpreadsheetDownloadStyle: {
    margin: "2px 10px 0px 0px",
    lineHeight: "35px",
    fontSize: 12,
    marginLeft: 20,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
};
